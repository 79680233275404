import { FormGroup, Validators } from "@angular/forms";

export class FinanceFormValidate {
  public type: string = '';
  public form: FormGroup = new FormGroup<any>({});

  constructor(form: FormGroup, type: string) {
    this.form = form;
    this.type = type;
  }

  private setValidators(controls: string[]): void {
    this.form.clearValidators();
    controls.forEach(control => {
      this.form.get(control)?.setValidators([Validators.required]);
      this.form.get(control)?.updateValueAndValidity();
    });
  }
  
  public updateValueAndValidity(): void {
    this.form.clearValidators();
    this.form.updateValueAndValidity();
  }

  public validateVendorForm(): void {
    const vendor = ['vendorId', 'reference', 'termsId', 'date', 'dueDate'];
    this.setValidators(vendor)
  }

  public validateInvoiceForm(): void {
    const invoice = ['customerId', 'reference', 'termsId', 'date', 'dueDate'];
    this.setValidators(invoice)
  }

  public validateChecksForm(): void {
    const controls = ['entityId', 'reference'];
    this.setValidators(controls)
  }

  public validateChargesForm(): void {
    const controls = ['customerId'];
    this.setValidators(controls)
  }

  public validateCreditMemoForm(): void {
    const controls = ['customerId', 'reference', 'termsId'];
    this.setValidators(controls)
  }
  
  public validateJournalEntriesForm(): void {
    const controls = ['date', 'reference'];
    this.setValidators(controls)
  }

  public validateFrequencyForm(oneTime: boolean = true): void {

    if(oneTime) {
      this.form.get('frequency')?.clearValidators();
    } else {
      console.log('test');
      this.form.get('frequency')?.setValidators([Validators.required]);
    }
    this.form.get('frequency')?.updateValueAndValidity();
  }
}
