import { CommonModule } from "@angular/common";
import { Component, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { iconLib } from "@app/core/const/roam-icon";
import { IAssociation } from "@app/shared/interfaces";
import { AssociationService } from "@app/shared/services/association.service";
import { InlineSVGModule } from "ng-inline-svg-2";
import { Subject, takeUntil } from "rxjs";
import { RoamButtonComponent } from "../../button/roam-button/roam-button.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RoamButtonComponent,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    InlineSVGModule,
    MatButtonModule,
  ],
  selector: "app-dialog-select-association",
  templateUrl: "./dialog-association.component.html",
  styleUrls: ["./dialog-association.component.scss"],
})
export class DialogSelectAssociationComponent {
  public icon = iconLib;
  #destroy = new Subject<void>();
  #api = inject(AssociationService);
  readonly dialogRef = inject(MatDialogRef<DialogSelectAssociationComponent>);
  next() {
    const selectedKey = this.state.selectedAssociationKey();
    this.dialogRef.close(selectedKey);
  }
  close() {
    this.dialogRef.close(null);
  }

  readonly state = {
    keyword: signal(""),
    associations: signal<IAssociation[]>([]),
    selectedAssociationKey: signal<string | "all">("all"),
  };

  protected displayedAssociations = computed(() => {
    const keyword = this.state.keyword();
    return this.state
      .associations()
      .filter(x => x.name?.toLowerCase().includes(keyword.toLowerCase()));
  });

  protected selectedAssociation = computed(() => {
    const key = this.state.selectedAssociationKey();
    return this.state.associations().find(v => v.id === key);
  });

  constructor() {
    this.#api
      .getAllAssocations()
      .pipe(takeUntil(this.#destroy))
      .subscribe(resp => {
        this.state.associations.set(resp.properties || []);
      });
  }
}
