<mat-dialog-content>
    <div class="financials-dialog" [formGroup]="form">

      <div class="header-panel">
          <p class="details-header">{{ editMode ? currentList.dialogEditModeTitle : currentList.dialogTitle }}</p>
          @if(!editMode) {
            <app-roam-button [class]="'btn btn-o-black flat'"
                     [label]="'Save & Exit'"
                     [isLoading]="loadingSave.exit()"
                     [disabled]="loadingSave.exit()"
                     (onClick)="saveData('exit')"
            />
          }
        </div>

        <div class="financials-dialog__body" mat-dialog-content>
            <div class="f-column justify-between">
                <div class="w-100">
                    <ng-container [ngSwitch]="data.type">

                      <!-- Bills -->

                      <div class="dialog-container" *ngSwitchCase="'bills'">
                          <div class="d-flex align-end">
                              <div class="dialog-account invoice-account w-100">

                                  <app-roam-select className="radius-10" placeholder="Select a vendor account"
                                                   formControlName="vendorId" [items]="vendors" [loading]="loader.vendor"
                                                   [searchable]="true" [ctr]="form.get('vendorId')" [ctrName]="'vendorId'"
                                                   />

                                  <app-roam-input name="Reference"
                                                  formControlName="reference"
                                                  [ctr]="form.get('reference')"
                                                  [ctrName]="'reference'"
                                                  [isRef]="true"
                                                  />

                                  <app-roam-select className="radius-10" placeholder="Select Terms"
                                                   [items]="terms"
                                                   formControlName="termsId"
                                                   [loading]="loader.term"
                                                   [searchable]="true"
                                                   [ctr]="form.get('termsId')" [ctrName]="'termsId'"
                                                   (selectedItem)="onSelectTerm($event)"
                                                   />
                              </div>
                          </div>
                          <hr>
                          <div class="bill-detail">
                            <h3 class="tc-black fw-500 pb-8">Bill Details</h3>
                              <div class="bill-date-wrapper">
                                  <app-roam-datepicker name="Bill Date" formControlName="date"
                                                       [ctrName]="'date'" [ctr]="form.get('date')" />

                                  <app-roam-datepicker name="Due Date" formControlName="dueDate"
                                                       [ctrName]="'dueDate'" [ctr]="form.get('dueDate')" />

                              </div>
                              <hr class="mt-24">
                          </div>

                          <h3 class="tc-black fw-500">Item Details</h3>
                          <div class="dialog-details-wrapper mt-12" formArrayName="detailsArray">
                              <ng-container *ngFor="let detail of detailsArray.controls; let index = index">
                                  <div class="align-center gap-9 w-100" [formGroupName]="index">
                                      <div class="dialog-details bill-details">
                                        <app-roam-select
                                          className="radius-10"
                                          placeholder="Expense Account"
                                          [items]="expenses"
                                          (selectedItem)="onSelectExpense($event, index)"
                                          formControlName="accountId"
                                          [panelWidth]="'360px'"
                                          [searchable]="true" />

                                        <app-roam-select
                                          className="radius-10"
                                          formControlName="customerId"
                                          placeholder="Customer to Bill"
                                          [items]="customers"
                                          [panelWidth]="'360px'"
                                          [searchable]="true" />

                                          <div class="filter-list">
                                          <mat-checkbox formControlName="billable"></mat-checkbox>
                                        </div>
                                        <app-roam-input name="Memo"
                                          formControlName="memo" />

                                        <app-roam-input name="Amount"
                                          formControlName="amount"
                                          [currency]="true"
                                          (valueChange)="onChangeAmount()" />

                                      </div>
                                      <ng-container *ngTemplateOutlet="deleteAction; context: {$implicit: index}"></ng-container>
                                  </div>
                              </ng-container>
                          </div>
                      </div>

                      <!-- Add Vendor-->

                      <div class="dialog-container" *ngSwitchCase="'add-vendor'">
                        <div class="d-flex align-end">
                          <div class="dialog-account vendor-info w-100">
                            <app-roam-select placeholder="Select Customer Account" [items]="customers" formControlName="customerId" className="radius-10"></app-roam-select>
                            <app-roam-input name="Reference" formControlName="reference"></app-roam-input>
                            <app-roam-select placeholder="Select Terms" className="radius-10" [items]="terms" formControlName="termsId"></app-roam-select>
                          </div>
                          <ng-container *ngTemplateOutlet="balance; context: {$implicit: totalAmount}"></ng-container>
                        </div>
                        <ng-container *ngTemplateOutlet="radioBtn"></ng-container>
                        <div class="bill-date-wrapper">
                          <app-roam-datepicker name="Bill Date" formControlName="date"></app-roam-datepicker>
                          <app-roam-datepicker name="Due Date" formControlName="dueDate"></app-roam-datepicker>
                          <app-roam-select *ngIf="frequency === 'recurring'" className="radius-10" placeholder="Frequency" [items]="frequencies"></app-roam-select>
                        </div>
                        <div class="dialog-details-wrapper">
                          <div class="dialog-details vendor">
                            <app-roam-select placeholder="Expense Account*"
                              className="radius-10"
                              formControlName="accountId"
                              [items]="expenses"
                              [searchable]="true"
                            />

                            <app-roam-select placeholder="Customer to Bill"
                              className="radius-10"
                              [items]="customers"
                              formControlName="customerId"
                              [searchable]="true"
                            />

                            <app-roam-input formControlName="description"
                              name="Description"
                            />

                            <app-roam-input name="Qty"
                              formControlName="quantity"
                              (valueChange)="onChangeChargePrice()"
                            />

                            <app-roam-input name="Unit Price"
                              formControlName="rate"
                              [currency]="true"
                              (valueChange)="onChangeChargePrice()"
                            />

                            <app-roam-input name="Amount USD"
                              formControlName="amount"
                              [currency]="true"
                              [readOnly]="true"></app-roam-input>
                          </div>
                        </div>
                      </div>

                      <!-- Bill Payments -->

                      <div class="dialog-container" *ngSwitchCase="'bill-payments'">
                        <div class="d-flex align-end justify-between">
                          <div class="dialog-account deposit-wrapper">
                            <app-roam-select placeholder="Vendor" [items]="vendors" (selectedItem)="onSelectVendor(selectedAssociationId() || '', $event)" className="radius-10" [searchable]="true" formControlName="vendorId"></app-roam-select>
                            <app-roam-datepicker name="Payment Date" formControlName="date"></app-roam-datepicker>
                            <app-roam-input name="Reference" formControlName="reference"></app-roam-input>
                          </div>
                        </div>
                        <div class="dialog-details-wrapper filter-list" *ngIf="openBills.length">
                          <p class="pay-bill-header">Unpaid Bills</p>
                          <div class="pay-bill-total pay-total-header">
                            <mat-checkbox (change)="onAllSelectToggle($event.checked)" formControlName="allChecked" [checked]="form.controls['allChecked'].value"></mat-checkbox>
                            <p>Due date</p>
                            <p class="roam-description">Description</p>
                            <p class="roam-description w-100 align-right">Original Amount4</p>
                            <p class="align-right">Open balance</p>
                            <p class="align-right">Payment</p>
                          </div>

                          <div formArrayName="detailsArray">
                            <ng-container *ngFor="let detail of detailsArray.controls; let index = index">
                              <div class="pay-bill-total" [formGroupName]="index">

                                <mat-checkbox (change)="onCheck($event, checked, index)" [checked]="checked.value" formControlName="checked" #checked></mat-checkbox>
                                <input formControlName="dueDate" readonly />
                                <input formControlName="description" readonly />
                                <input class="align-right" formControlName="amountDue" readonly />
                                <input class="align-right" formControlName="openBalance" readonly />
                                <div class="d-flex align-center justify-end">
                                  <app-roam-input [floatingLabel]="false" placeholder="$100.0" formControlName="amount" (valueChange)="onChangeCheckedAmount()"></app-roam-input>
                                </div>

                              </div>
                            </ng-container>
                          </div>

                        </div>

                      </div>

                      <!-- Charges -->

                      <div class="dialog-container" *ngSwitchCase="'charges'">
                          <div class="d-flex align-end">
                              <div class="dialog-account invoice-account w-100 mb-20">
                                <app-roam-select 
                                  placeholder="Select Customer Account" 
                                  [items]="customers" 
                                  formControlName="customerId" 
                                  [ctr]="form.get('customerId')" 
                                  [ctrName]="'customerId'"
                                  className="radius-10" />
                                  
                                <app-roam-input 
                                  name="Reference" 
                                  formControlName="reference" />
                              </div>
                              <ng-container *ngTemplateOutlet="balance; context: {$implicit: totalAmount}"></ng-container>
                          </div>
                          <ng-container *ngTemplateOutlet="radioBtn"></ng-container>
                          <div class="bill-date-wrapper mb-20">
                              <app-roam-datepicker name="Charge Date" formControlName="date"></app-roam-datepicker>
                              <app-roam-datepicker name="Due Date" formControlName="dueDate"></app-roam-datepicker>
                              <app-roam-select *ngIf="frequency === 'recurring'" className="radius-10" placeholder="Frequency" [items]="frequencies"></app-roam-select>
                          </div>
                          <div class="dialog-details-wrapper">
                              <div class="dialog-details charge-details">
                                  <app-roam-select placeholder="Item"
                                    [items]="items" className="radius-10"
                                    formControlName="itemId"
                                    (selectedItem)="onSelectChargeItem($event)"
                                    [searchable]="true"
                                  />

                                  <app-roam-input name="Memo"
                                    formControlName="memo"
                                  />

                                  <app-roam-input name="Qty"
                                    class="text-left"
                                    formControlName="quantity"
                                    (valueChange)="onChangeChargePrice()" />

                                  <app-roam-input name="Unit Price"
                                    formControlName="rate"
                                    [currency]="true"
                                    (valueChange)="onChangeChargePrice()"
                                  />

                                  <app-roam-input name="Total"
                                    formControlName="amount"
                                    [currency]="true"
                                    [readOnly]="true"></app-roam-input>
                              </div>
                          </div>
                      </div>

                      <!-- Checks -->

                      <div class="dialog-container" *ngSwitchCase="'checks'">
                        <div class="d-flex align-end">
                          <div class="dialog-account invoice-account w-100 mb-20">
                            <app-roam-select 
                              placeholder="Select a Payee" 
                              [items]="entities" 
                              className="radius-10" 
                              formControlName="entityId"
                              [ctr]="form.get('entityId')" [ctrName]="'entityId'"
                              [searchable]="true" />

                            <app-roam-input 
                              name="Reference" 
                              formControlName="reference" 
                              [ctr]="form.get('reference')" [ctrName]="'reference'"
                            />
                          </div>

                          <ng-container *ngTemplateOutlet="balance; context: {$implicit: totalAmount}"></ng-container>
                        </div>
                        <ng-container *ngTemplateOutlet="radioBtn"></ng-container>
                        <div class="bill-date-wrapper mb-20">
                          <app-roam-datepicker name="Check Date" formControlName="date"></app-roam-datepicker>
                        </div>
                        <div class="dialog-details-wrapper" formArrayName="detailsArray">
                          <ng-container *ngFor="let detail of detailsArray.controls; let index = index">
                            <div class="align-center gap-9" [formGroupName]="index">
                              <div class="dialog-details check-details">
                                <app-roam-select placeholder="Account" [items]="expenses" formControlName="accountId" className="radius-10" [searchable]="true"></app-roam-select>
                                <app-roam-select placeholder="Customer to Bill" [items]="customers" className="radius-10" formControlName="customerId" [searchable]="true"></app-roam-select>
                                <div class="filter-list"><mat-checkbox formControlName="billable"></mat-checkbox></div>
                                <app-roam-input name="Memo" formControlName="memo"></app-roam-input>
                                <app-roam-input name="Amount" formControlName="amount" (valueChange)="onChangeCheckAmount()" ></app-roam-input>
                              </div>
                              <ng-container *ngTemplateOutlet="deleteAction; context: {$implicit: index}"></ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div>

                    <!-- Credit Memos -->

                      <div class="dialog-container credit-memo" *ngSwitchCase="'credit-memos'">
                          <div class="d-flex align-end">
                              <div class="dialog-account invoice-account w-100 mb-20">
                                  <app-roam-select 
                                    placeholder="Select Customer Account" 
                                    [items]="customers" 
                                    formControlName="customerId" 
                                    [ctr]="form.get('customerId')" [ctrName]="'customerId'"
                                    className="radius-10" />

                                  <app-roam-input 
                                    name="Reference" 
                                    formControlName="reference"
                                    [ctr]="form.get('reference')" [ctrName]="'reference'"
                                  />

                                  <app-roam-select 
                                    placeholder="Select Terms" 
                                    className="radius-10" 
                                    [items]="terms" 
                                    formControlName="termsId"
                                    [ctr]="form.get('termsId')" [ctrName]="'termsId'"
                                  />
                              </div>
                              <ng-container *ngTemplateOutlet="balance; context: {$implicit: totalAmount}"></ng-container>
                          </div>
                          <div class="bill-date-wrapper invoice-date mb-20">
                              <app-roam-datepicker 
                                name="Bill Date" 
                                formControlName="date" 
                                [ctr]="form.get('date')" [ctrName]="'date'"
                              />

                              <app-roam-datepicker 
                                name="Due Date" 
                                formControlName="dueDate" 
                                [ctr]="form.get('dueDate')" [ctrName]="'dueDate'"
                              />
                          </div>
                          <div class="dialog-details-wrapper" formArrayName="detailsArray">
                              <ng-container *ngFor="let detail of detailsArray.controls; let index = index">
                                  <div class="align-center gap-9" [formGroupName]="index">
                                      <div class="dialog-details credit-memo-details">
                                        <app-roam-select placeholder="Item" (selectedItem)="onSelectInvoiceItem($event, index)" formControlName="itemId" [searchable]="true" [items]="items" className="radius-10"></app-roam-select>
                                        <app-roam-input formControlName="description" name="Description"></app-roam-input>
                                        <app-roam-input name="Qty" formControlName="quantity" (valueChange)="onChangePrice($event, index)"></app-roam-input>
                                        <app-roam-input name="Unit Price" formControlName="rate" (valueChange)="onChangePrice($event, index)"></app-roam-input>
                                        <app-roam-input name="Total" formControlName="amount" (valueChange)="onChangeAmount()" [readOnly]="true"></app-roam-input>
                                      </div>
                                    <ng-container *ngTemplateOutlet="deleteAction; context: {$implicit: index}"></ng-container>
                                  </div>
                              </ng-container>
                          </div>
                      </div>

                    <!-- Deposits -->

                    <div class="dialog-container" *ngSwitchCase="'deposits'">
                      <div class="d-flex align-end justify-between">
                        <div class="dialog-account deposit-wrapper">
                          <app-roam-datepicker name="Deposit Date" formControlName="date"></app-roam-datepicker>
                          <app-roam-input name="Reference"></app-roam-input>
                          <app-roam-select placeholder="Deposit Account" className="radius-10"></app-roam-select>
                          <app-roam-input name="Account Balance" className="grey-bg"></app-roam-input>
                        </div>
                        <ng-container *ngTemplateOutlet="balance; context: {$implicit: totalAmount}"></ng-container>
                      </div>
                      <div class="dialog-details-wrapper filter-list" *ngIf="undeposited.length">
                        <p class="pay-bill-header">Unpaid Bills</p>
                        <div class="pay-bill-total pay-total-header">
                          <mat-checkbox (change)="onAllSelectToggle($event.checked)" formControlName="allChecked" [checked]="form.controls['allChecked'].value"></mat-checkbox>
                          <p>Due date</p>
                          <p class="roam-description">Customer</p>
                          <p class="roam-description w-100 align-right">Deposit Amount</p>
                          <p class="align-right">Open balance</p>
                          <p class="align-right">Payment</p>
                        </div>
                        <div formArrayName="detailsArray">
                          <ng-container *ngFor="let detail of detailsArray.controls; let index = index">
                            <div class="pay-bill-total" [formGroupName]="index">
                              <mat-checkbox (change)="onCheck($event, checked, index)" [checked]="checked.value" formControlName="checked" #checked></mat-checkbox>
                              <input formControlName="dueDate" readonly />
                              <input formControlName="description" readonly />
                              <input class="align-right" formControlName="amountDue" readonly />
                              <input class="align-right" formControlName="openBalance" readonly />
                              <div class="d-flex align-center justify-end">
                                <app-roam-input [floatingLabel]="false" placeholder="$100.0" formControlName="amount" (valueChange)="onChangeCheckedAmount()"></app-roam-input>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <!-- Invoices -->

                    <div class="dialog-container invoice" *ngSwitchCase="'invoices'">
                      <div class="d-flex align-end">
                        <div class="dialog-account invoice-account w-100">
                          <app-roam-select placeholder="Select Customer Account"
                                           [items]="customers"
                                           formControlName="customerId"
                                           className="radius-10"
                                           [searchable]="true"
                                           [ctrName]="'customerId'"
                                           [ctr]="form.get('customerId')"
                          />

                          <app-roam-input name="Reference"
                                          formControlName="reference"
                                          [ctrName]="'reference'"
                                          [ctr]="form.get('reference')"
                          />

                          <app-roam-select placeholder="Select Terms"
                                           className="radius-10"
                                           formControlName="termsId"
                                           [items]="terms"
                                           [ctrName]="'termsId'" [ctr]="form.get('termsId')"
                                           (selectedItem)="onSelectTerm($event)"
                          />
                        </div>
                      </div>
                      <hr>
                      <div>
                        <h3 class="tc-black fw-500">Bill Details</h3>
                        <div class="bill-date-wrapper invoice-date">
                          <app-roam-datepicker name="Bill Date" formControlName="date"
                                               [ctrName]="'date'" [ctr]="form.get('date')"
                          ></app-roam-datepicker>
                          <app-roam-datepicker name="Due Date" formControlName="dueDate"
                                               [ctrName]="'dueDate'" [ctr]="form.get('dueDate')"
                          ></app-roam-datepicker>
                        </div>
                      </div>

                      <hr>
                      <div>
                        <h3 class="tc-black fw-500">Item Details</h3>
                        <div class="dialog-details-wrapper" formArrayName="detailsArray">
                          <ng-container *ngFor="let detail of detailsArray.controls; let index = index">
                            <div class="align-center gap-9 w-100" [formGroupName]="index">
                              <div class="dialog-details invoice-details">
                                <app-roam-select placeholder="Item"
                                    formControlName="itemId"
                                    [searchable]="true"
                                    [items]="items" className="radius-10"
                                    (selectedItem)="onSelectInvoiceItem($event, index)"
                                />
                                <app-roam-input name="Description"
                                  formControlName="description"
                                />

                                <app-roam-input
                                  name="Qty"
                                  formControlName="quantity"
                                  (valueChange)="onChangePrice($event, index)" />

                                <app-roam-input name="Unit Price"
                                  formControlName="rate"
                                  (valueChange)="onChangePrice($event, index)" />

                                <app-roam-input name="Total"
                                  formControlName="amount"
                                  [currency]="true"
                                  [readOnly]="true"
                                  (valueChange)="onChangeAmount()"
                                />

                              </div>
                              <ng-container *ngTemplateOutlet="deleteAction; context: {$implicit: index}"></ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <!-- Received Payments -->

                      <div class="dialog-container" *ngSwitchCase="'received-payments'">
                          <div class="d-flex align-end">
                              <div class="dialog-account invoice-account w-100">
                                <app-roam-select placeholder="Customer"
                                  formControlName="customerId"
                                  className="radius-10"
                                  [items]="customers"
                                  (selectedItem)="onSelectCustomer($event)"
                                  [searchable]="true" />

                                <app-roam-input name="Reference"
                                  formControlName="reference" />

                                <app-roam-select placeholder="Payment Method"
                                  className="radius-10"
                                  [items]="paymentMethods"
                                  [searchable]="true"
                                  formControlName="paymentMethodId" />

                                <app-roam-datepicker name="Payment Date"
                                  formControlName="date" />
                              </div>
                              <ng-container *ngTemplateOutlet="balance; context: {$implicit: totalAmount}"></ng-container>
                          </div>
                          <div class="dialog-details-wrapper filter-list" *ngIf="openInvoices.length">
                            <p class="pay-bill-header">Unpaid Invoices</p>
                            <div class="pay-bill-total pay-total-header">
                              <mat-checkbox (change)="onAllSelectToggle($event.checked)" formControlName="allChecked" [checked]="form.controls['allChecked'].value"></mat-checkbox>
                              <p>Due date</p>
                              <p class="roam-description">Description</p>
                              <p class="roam-description w-100 align-right">Original Amount5</p>
                              <p class="align-right">Open balance</p>
                              <p class="align-right">Payment</p>
                            </div>

                            <div formArrayName="detailsArray">
                              <ng-container *ngFor="let detail of detailsArray.controls; let index = index">
                                <div class="pay-bill-total" [formGroupName]="index">

                                  <mat-checkbox (change)="onCheck($event, checked, index)" [checked]="checked.value" formControlName="checked" #checked></mat-checkbox>
                                  <input formControlName="dueDate" readonly />
                                  <input formControlName="description" readonly />
                                  <input class="align-right" formControlName="amountDue" readonly />
                                  <input class="align-right" formControlName="openBalance" readonly />
                                  <div class="d-flex align-center justify-end">
                                    <app-roam-input [floatingLabel]="false" placeholder="0" formControlName="amount" (valueChange)="onChangeCheckedAmount()"></app-roam-input>
                                  </div>

                                </div>
                              </ng-container>
                            </div>

                          </div>

                      </div>

                      <!-- Journal Entries -->

                      <div class="dialog-container invoice" *ngSwitchCase="'journal-entries'">
                          <div class="dialog-account invoice-account w-100 mb-20">
                            
                              <app-roam-datepicker 
                                name="Entry Date*" 
                                formControlName="date"
                                [ctrName]="'date'" [ctr]="form.get('date')"
                              />
                              
                              <app-roam-input name="Reference"
                                formControlName="referance"
                                [ctrName]="'reference'" [ctr]="form.get('reference')"
                              />
                          </div>
                        <div class="dialog-details-wrapper">
                              <div class="align-center gap-9" *ngFor="let detail of details; let index = index">
                                  <div class="dialog-details entry-details">
                                    <app-roam-select placeholder="Account" [items]="accounts" className="radius-10" (selectedItem)="onSelectJournalAccount($event)" formControlName="accountId" ></app-roam-select>
                                      <app-roam-input name="Description" formControlName="memo"></app-roam-input>
                                      <app-roam-input name="Balance" formControlName="balance" className="grey-bg" [readOnly]="true"></app-roam-input>
                                      <app-roam-input name="Debit Amount" formControlName="debits"></app-roam-input>
                                      <app-roam-input name="Credit Amount" formControlName="credits"></app-roam-input>
                                  </div>
                                  <ng-container *ngTemplateOutlet="deleteAction; context: {$implicit: index}"></ng-container>
                              </div>
                              <div class="total-entry-details roam-description">
                                  <p></p>
                                  <p>Total</p>
                                  <p>0.00</p>
                                  <p>0.00</p>
                              </div>
                          </div>
                      </div>

                      <!-- Transfers -->

                      <div class="dialog-container" *ngSwitchCase="'transfers'">
                          <div class="dialog-account transfer-reference w-100">
                              <app-roam-datepicker name="Transfer Date" formControlName="date"></app-roam-datepicker>
                              <app-roam-input name="Reference" formControlName="reference"></app-roam-input>
                          </div>
                          <div class="transfer-content">
                            <app-roam-select placeholder="Transfer From" [items]="accounts" className="radius-10" (selectedItem)="onSelectTransferAccount($event, 'from')" formControlName="fromAccountId" ></app-roam-select>
                            <app-roam-input name="Account Balance" formControlName="fromBalance" className="grey-bg" [readOnly]="true"></app-roam-input>
                            <app-roam-select placeholder="Transfer To" [items]="accounts" className="radius-10" (selectedItem)="onSelectTransferAccount($event, 'to')" formControlName="toAccountId" ></app-roam-select>
                            <app-roam-input name="Account Balance" formControlName="toBalance" className="grey-bg" [readOnly]="true"></app-roam-input>
                            <app-roam-input name="Transfer Amount" formControlName="amount" (valueChange)="onChangeTransferAmount()"></app-roam-input>
                          </div>
                      </div>

                    </ng-container>
                    <div>

                    <!-- Line Item Buttons -->
                    <div class="d-flex align-center gap-9" *ngIf="data.type !== 'transfers' && data.type !== 'bill-payments' && data.type !== 'charges' && data.type !== 'received-payments' && data.type !== 'deposits'">
                            <ng-container *ngIf="data.type === 'deposits'">
                                <p class="roam-text fw-400 grey-text" *ngIf="selectedItems > 0">{{ selectedItems }} Bill selected</p>
                                <button mat-icon-button class="border-btn" (click)="onAllSelectToggle(true)">
                                    <mat-icon>add</mat-icon>
                                    <span class="roam-text fw-400">Select All</span>
                                </button>
                                <button mat-icon-button class="border-btn" (click)="onAllSelectToggle(false)">
                                    <mat-icon>add</mat-icon>
                                    <span class="roam-text fw-400">Unselect All</span>
                                </button>
                            </ng-container>
                            <button mat-icon-button class="border-btn btn-h" (click)="onAddItem()">
                                <mat-icon>add</mat-icon>
                                <span class="roam-text fw-400 tc-black">Add Line</span>
                            </button>
                            <button mat-button (click)="onClearAll()">
                                <span class="roam-text fw-400 text-underline">Clear Line Items</span>
                            </button>
                      </div>
                      <hr>

                      <!-- Memo -->

                      <h3 class="tc-black fw-500 pb-12">Additional Information</h3>
                      <div class="financials-dialog__memo mt-1">
                          <textarea placeholder="Add Memo"
                                    formControlName="memo"
                                    maxLength="255"
                          ></textarea>
                          <div class="counter">
                            {{ form.get('memo')?.value?.length || 0}}/255
                          </div>
                      </div>

                    </div>

                  <!-- Attachments -->

                  <div>
                    <mat-label class="font-14px tc-black fw-500"> Attachments </mat-label>
                    <app-file-uploader [(data)]="attachments" [config]="modelConfig()" />                    
                  </div>

                  <!-- Email Receipt -->

                  <div class="pt-20" *ngIf="data.type === 'received-payments' || data.type === 'invoices'">
                    <mat-label class="font-14px tc-black fw-500"> Sharing </mat-label>
                    <div class="mt-8 d-grid gap-16">
                      <app-roam-toggle-slider [label]="'Email a copy to the customer'"
                                              [labelClass]="'fw-500'"
                                              [name]="'copyCustomer'"
                      />
                      <app-roam-toggle-slider [label]="'Email a copy to the management company'"
                                              [labelClass]="'fw-500'"
                                              [name]="'copyManagementCompany'"
                      />
                    </div>
                  </div>
                    <!-- Check Preview -->

                    <div class="dialog-container" *ngIf="data.type === 'checks'">
                        <div class="bill-cheque-wrapper d-flex">
                            <div class="align-start-column gap-28 w-100">
                                <div class="justify-between w-100">
                                    <div class="align-center order-input-wrapper">
                                        <span class="roam-text fw-400">Pay to the Order of </span>
                                        <input class="roam-text fw-400" id="previewCustomerName" value="?????" />
                                    </div>
                                    <div class="align-center f-column gap-15">
                                        <div class="align-center justify-end">
                                            <span class="roam-text fw-400">No.</span>
                                            <input placeholder="To Print" class="roam-text fw-400 w-50" value="?????" />
                                        </div>
                                        <div class="align-center justify-end">
                                            <span class="roam-text fw-400">Date</span>
                                            <input class="roam-text fw-400 w-50" id="previewDate" value="?????" />
                                        </div>
                                        <div class="align-center justify-end">
                                            <span class="roam-text fw-400">S</span>
                                            <input class="roam-text fw-400 w-50" id="previewAmount" value="?????"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="align-start-column gap-28 w-100">
                                    <div class="align-center w-100">
                                        <input class="roam-text fw-400 w-100" formControlName="amountWords" value="?????" />
                                        <span class="roam-text fw-400">Dollars</span>
                                    </div>
                                    <div class="align-center cheque-textarea-wrapper gap-9">
                                        <span class="roam-text fw-400">Address</span>
                                        <textarea class="roam-text fw-400">?????</textarea>
                                    </div>
                                    <div class="align-center w-100">
                                        <span class="roam-text fw-400">Memo</span>
                                        <input class="roam-text fw-400 w-100" id="previewMemo" value="?????" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="total-amount">
                      <span>Total</span>
                      <span><ng-container *ngTemplateOutlet="balance; context: {$implicit: totalAmount}"></ng-container></span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->

        <div class="justify-between financials-dialog__footer">
            <button mat-button class="text-btn-underline" mat-dialog-close>
              {{ editMode ? 'Close' : 'Cancel'}}
            </button>

            <div>
              <app-roam-button
                  [label]="editMode ? 'Save' : saveAction"
                  [loader]="true"
                  [isLoading]="loadingSave.new()"
                  (onClick)="saveData('new')"
                />
            </div>
        </div>

    </div>
</mat-dialog-content>

<ng-template #balance let-totalAmount>
    <div class="dialog-balance">
        <p class="details-title"></p>
        <p class="balance-text">{{ totalAmount | currency }}</p>
    </div>
</ng-template>

<ng-template #radioBtn>
    <div class="checkbox-wrapper">
      <div class="d-flex gap-16">
        <div class="radio-chip" (change)="updateFrequencyForm()">
          <input type="radio" name="billType" id="onetime" value="onetime" [(ngModel)]="frequency">
          <label for='onetime'>One Time</label>
        </div>
        <div class="radio-chip" (change)="updateFrequencyForm()">
          <input type="radio" name="billType" id="recurring" value="recurring" [(ngModel)]="frequency">
          <label for='recurring'>Recurring Bill</label>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #deleteAction let-index>
    <button [style.opacity]="index === 0 ? '0' : '100%'"
            [style.pointer-events]="index === 0 ? 'none' : 'cursor'"
            mat-icon-button class="trash-icon"
            (click)="onDeleteItem(index)">
        <span inlineSVG="/assets/svg/trash.svg"></span>
    </button>
</ng-template>
