import { addDays } from "date-fns";
import {
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IVendor } from "@app/shared/interfaces/vendor.interface";
import { VendorService } from "@app/shared/services/vendor.service";
import { AccountingService } from "@app/shared/services/accounting.service";
import { frequencies } from "../../utils/data/financial-list.data";
import {
  IAccount,
  IBill,
  IBillResponse,
  IChargeResponse,
  ICheckResponse,
  IInvoice,
  IItem,
  IPaymentMethod,
  IReceivedPayment,
  ITerm,
  ITransferResponse,
} from "@app/shared/interfaces/accounting.interface";
import { subMenus } from "@app/utils/data/menu";
import { forkJoin, Observable, Subject, takeUntil } from "rxjs";
import { ICustomer } from "@app/shared/interfaces/customer.interface";
import { IResponse } from "@app/shared/interfaces/response.interface";
import { ToWords } from "to-words";
import { LogService } from "@app/shared/services/log.service";
import { FinanceFormValidate } from "@app/pages/financials/shared/class/finance-form-validate";
import { IDialogData } from "@app/shared/interfaces/dialog-data.interface";
import { IEntity } from "@app/shared/interfaces/entity.interface";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { convertCurrencyToNumber } from "@app/core/helper/currency.helper";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { AttachmentType } from "../../shared/const/attachment-type.const";

type SaveType = "exit" | "new";

@Component({
  selector: "app-financials-dialog",
  templateUrl: "./financials-dialog.component.html",
  styleUrls: ["./financials-dialog.component.scss"],
})
export class FinancialsDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject();
  financialListData = subMenus["financials"];
  currentList: any;
  expenses: IAccount[] = [];
  accounts: IAccount[] = [];
  items: IItem[] = [];
  selectedAssociationId = signal<string | null>(null);
  selectedManagerId = signal<string | null>(null);
  frequencies = frequencies;
  selectedItems: number = 0;
  totalAmount: number = 0;
  isInlineAttachment: boolean = false;
  frequency: string = "onetime";
  saveAction: string = "Save & New";
  vendors: IVendor[] = [];
  entities: IEntity[] = [];
  terms: ITerm[] | any = [];
  customers: ICustomer[] = [];
  paymentMethods: IPaymentMethod[] = [];
  openBills: IBill[] = [];
  undeposited: IReceivedPayment[] = [];
  openInvoices: IInvoice[] = [];
  filteredCustomer$!: Observable<ICustomer[]>;
  details = [{ checked: false }];
  files: any = [];
  validatorForm: any;
  loader: any = { vendor: false, term: false };
  loadingSave = {
    exit: signal<boolean>(false),
    new: signal<boolean>(false),
  };
  saveType: SaveType = "new";
  #destroyRef = inject(DestroyRef);

  // File Upload Config
  protected modelId = signal<string>("");
  protected attachmentModel = signal<AttachmentType | string>("");
  protected attachments = signal([]);
  protected modelConfig = computed(() => {
    return {
      propertyId: this.selectedAssociationId()!,
      modelId: this.modelId(),
      model: this.attachmentModel(),
    };
  });

  form: FormGroup = this.formBuilder.group({
    vendorId: [null],
    customerId: [""],
    entityId: [""],
    termsId: [null],
    accountId: [""],
    itemId: [""],
    fromAccountId: [""],
    toAccountId: [""],
    paymentMethodId: [""],
    date: [new Date()],
    dueDate: [],
    amount: [0],
    amountWords: [""],
    balance: [0],
    deposit: [0],
    rate: [0],
    quantity: [1],
    fromBalance: [0],
    toBalance: [0],
    reference: [null],
    memo: [""],
    description: [""],
    frequency: [""],
    billable: [false],
    allChecked: [false],
    detailsArray: this.formBuilder.array([]),

    // Below I just added to make the form valid and not get error on shortcut
    debits: [0],
    credits: [0],
  });

  formItems!: FormGroup;

  get detailsArray() {
    return this.form.controls["detailsArray"] as FormArray;
  }

  get editMode() {
    return !!this.data.id;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private dialogRef: MatDialogRef<FinancialsDialogComponent>,
    private formBuilder: FormBuilder,
    private vendorsService: VendorService,
    private accountingService: AccountingService,
    private userConfig: UserConfigStore,
    private logService: LogService,
    private toastr: RoamToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.userConfig.selectedAssociationId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(id => {
        this.selectedAssociationId.set(id);
      });
    this.userConfig.selectedManagerId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(id => {
        this.selectedManagerId.set(id);
      });

    const recordId = this.data.id ? this.data.id : crypto.randomUUID();
    this.modelId.set(recordId);
  }

  ngOnInit(): void {
    this.logService.track("open-modal", this.data);
    this.currentList = this.financialListData.find(
      item => item.type === this.data.type
    );
    this.editMode ? this.getData() : this.onAddItem();
    this.validatorForm = new FinanceFormValidate(
      this.form,
      this.currentList.type
    );

    this.setLoader();
    this.generateFinancialDialogSection();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private generateFinancialDialogSection(): void {
    switch (this.currentList.type) {
      case "bills":
        this.generateBillSection();
        break;
      case "charges":
        this.generateChargeSection();
        break;
      case "checks":
        this.generateCheckSection();
        break;
      case "invoices":
        this.generateInvoiceSection();
        break;
      case "credit-memos":
        this.generateCreditMemoSection();
        break;
      case "journal-entries":
        this.generateJournalEntrySection();
        break;
      case "transfers":
        this.generateTransferSection();
    }
  }

  private generateBillSection(): void {
    this.validatorForm.validateVendorForm();
    this.attachmentModel.set(AttachmentType.BILL);
    this.selectedAssociationId() &&
      forkJoin({
        accounts: this.accountingService.getIncomeExpenseAccounts(
          this.selectedAssociationId()!,
          this.editMode
        ),
        terms: this.accountingService.getTerms(
          this.selectedAssociationId()!,
          this.editMode
        ),
        vendors: this.vendorsService.getVendors(
          this.selectedAssociationId()!,
          this.editMode
        ),
        customers: this.accountingService.getCustomers(
          this.selectedAssociationId()!,
          this.editMode
        ),
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ accounts, terms, vendors, customers }) => {
          this.expenses = accounts as unknown as IAccount[];
          this.terms = terms;
          this.vendors = vendors.data;
          this.customers = customers.data;
          this.setLoader(false);
        });
  }

  private generateChargeSection(): void {
    this.validatorForm.validateChargesForm();
    this.attachmentModel.set("CHARGES"); // TODO: AS AttachmentType
    this.selectedAssociationId() &&
      forkJoin({
        customers: this.accountingService.getCustomers(
          this.selectedAssociationId() || "",
          this.editMode
        ),
        items: this.accountingService.getItems(
          this.selectedAssociationId() || "",
          this.editMode
        ),
      }).subscribe(({ customers, items }) => {
        this.items = items.data;
        this.customers = customers.data;
      });
  }

  private generateCheckSection(): void {
    this.validatorForm.validateChecksForm();
    this.attachmentModel.set("CHECKS"); // TODO: AS AttachmentType
    this.selectedAssociationId()! &&
      forkJoin({
        entities: this.vendorsService.getEntities(
          this.selectedAssociationId()!,
          this.editMode
        ),
        accounts: this.accountingService.getAccounts(
          this.selectedAssociationId()!,
          this.editMode
        ),
        customers: this.accountingService.getCustomers(
          this.selectedAssociationId()!,
          this.editMode
        ),
        terms: this.accountingService.getTerms(
          this.selectedAssociationId()!,
          this.editMode
        ),
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ entities, accounts, customers, terms }) => {
          this.entities = entities.data;
          this.expenses = accounts["data"];
          this.terms = terms.data;
          this.customers = customers.data;
        });
  }

  private generateInvoiceSection(): void {
    this.validatorForm.validateInvoiceForm();
    this.attachmentModel.set(AttachmentType.INVOICE);
    this.selectedAssociationId()! &&
      forkJoin({
        customers: this.accountingService.getCustomers(
          this.selectedAssociationId()!,
          this.editMode
        ),
        terms: this.accountingService.getTerms(
          this.selectedAssociationId()!,
          this.editMode
        ),
        items: this.accountingService.getItems(
          this.selectedAssociationId()!,
          this.editMode
        ),
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ items, terms, customers }) => {
          this.items = items.data;
          this.terms = terms;
          this.customers = this.generateCustomerName(customers.data);
        });
  }

  private generateCreditMemoSection(): void {
    this.validatorForm.validateCreditMemoForm();
    this.attachmentModel.set(AttachmentType.CREDIT_MEMO);
    this.selectedAssociationId()! &&
      forkJoin({
        customers: this.accountingService.getCustomers(
          this.selectedAssociationId()!,
          this.editMode
        ),
        terms: this.accountingService.getTerms(
          this.selectedAssociationId()!,
          this.editMode
        ),
        items: this.accountingService.getItems(
          this.selectedAssociationId()!,
          this.editMode
        ),
      }).subscribe(({ items, terms, customers }) => {
        this.items = items.data;
        this.terms = terms.data;
        this.customers = customers.data;
      });
    this.selectedAssociationId() &&
      forkJoin({
        customers: this.accountingService.getCustomers(
          this.selectedAssociationId() || "",
          this.editMode
        ),
        terms: this.accountingService.getTerms(
          this.selectedAssociationId() || "",
          this.editMode
        ),
        items: this.accountingService.getItems(
          this.selectedAssociationId() || "",
          this.editMode
        ),
      }).subscribe(({ items, terms, customers }) => {
        this.items = items.data;
        this.terms = terms.data;
        this.customers = customers.data;
      });
  }

  private generateJournalEntrySection(): void {
    this.validatorForm.validateJournalEntriesForm();
    this.attachmentModel.set("journal-enteries"); //// TODO: AS AttachmentType
    this.selectedAssociationId() &&
      forkJoin({
        accounts: this.accountingService.getAccounts(
          this.selectedAssociationId() || "",
          this.editMode
        ),
      }).subscribe(({ accounts }) => {
        this.accounts = accounts["accounts"];
      });
  }

  private generateTransferSection(): void {
    this.selectedAssociationId() &&
      forkJoin({
        accounts: this.accountingService.getAccounts(
          this.selectedAssociationId() || "",
          this.editMode
        ),
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ accounts }) => {
          this.accounts = accounts["accounts"];
        });
  }

  private checkAllSelections() {
    this.form.patchValue({
      allChecked: !this.form.value.detailsArray.some(
        (control: any) => control.checked === false
      ),
    });
  }

  createBillDetailItems(): FormGroup {
    return this.formBuilder.group({
      id: [""],
      accountId: [""],
      quantity: [""],
      rate: [0],
      amount: [0],
      memo: [""],
      serviceDate: [null],
      customerId: [""],
      billable: [false],
    });
  }

  createCheckItems(): FormGroup {
    return this.formBuilder.group({
      id: [""],
      accountId: [""],
      customerId: [""],
      billable: [false],
      memo: [""],
      amount: [0],
      amountWords: [""],
    });
  }

  createInvoiceItems(): FormGroup {
    return this.formBuilder.group({
      id: [""],
      itemId: [""],
      description: [""],
      quantity: [0],
      rate: [0],
      amount: [0],
    });
  }

  onAddItem() {
    this.details.push({ checked: false });
    switch (this.currentList.type) {
      case "bills":
        this.formItems = this.createBillDetailItems();
        this.detailsArray.push(this.formItems);
        break;
      case "checks":
        this.formItems = this.createCheckItems();
        this.detailsArray.push(this.formItems);
        break;
      case "credit-memos":
      case "invoices":
        this.formItems = this.createInvoiceItems();
        this.detailsArray.push(this.formItems);
        break;
      case "charges":
        break;
    }
  }

  onClearAll() {
    this.detailsArray.reset();
  }

  onDeleteItem(index: number) {
    this.detailsArray.removeAt(index);
    this.details.splice(index, 1);
    this.getSelectedItems();
    this.onChangeAmount();
  }

  onCheck(event: any, item: any, index?: number) {
    if (index) {
      const detailsForm = this.detailsArray.controls[index] as FormGroup;
      detailsForm.get("amount")?.setValue(detailsForm.get("amountDue")?.value);
    }
    item.checked = event.checked;
    this.checkAllSelections();
    this.getSelectedItems();
  }

  getSelectedItems() {
    this.selectedItems = this.form.value.detailsArray.filter(
      (item: any) => item.checked
    ).length;
    this.onChangeCheckedAmount();
  }

  onAllSelectToggle(state: boolean) {
    let formArr = <FormArray>this.form.controls["detailsArray"];
    formArr.controls.map(control =>
      control.patchValue({
        checked: state,
      })
    );
    this.getSelectedItems();
  }

  onSelectTerm(termId: string): void {
    let dueDays = this.terms.find(
      (term: ITerm) => term.id === termId
    ).standardDueDays;
    this.form.get("dueDate")?.setValue(addDays(new Date(), +dueDays));
  }

  onSelectExpense(id: string, index: number) {
    let selectedExpense: any = this.expenses.find(item => item.id === id);
    let formArr = <FormArray>this.form.controls["detailsArray"];
    formArr.controls[index].patchValue({
      description: selectedExpense.description,
    });
  }

  onSelectJournalAccount(id: string) {
    let selectedAccount: any = this.accounts.find(item => item.id === id);
    this.form.get("balance")?.setValue(selectedAccount.balance);
  }

  onSelectTransferAccount(id: string, direction: "from" | "to") {
    let selectedAccount: any = this.accounts.find(item => item.id === id);
    if (direction === "from") {
      this.form.get("fromBalance")?.setValue(selectedAccount.balance);
    } else {
      this.form.get("toBalance")?.setValue(selectedAccount.balance);
    }
  }

  onSelectInvoiceItem(id: string, index: number) {
    let selectedItem: any = this.items.find(item => item.id === id);
    let formArr = <FormArray>this.form.controls["detailsArray"];
    formArr.controls[index].patchValue({
      description: selectedItem.description,
      rate: +selectedItem.price,
      amount: +selectedItem.price,
    });

    this.onChangeAmount();
  }

  onSelectVendor(propertyId: string, vendorId: string) {
    this.accountingService
      .getOpenBills(propertyId, vendorId)
      .subscribe((data: IBill[]) => {
        this.openBills = data;
        if (this.openBills.length) {
          this.openBills.map(bill => {
            const dueDate = new Date(bill.dueDate);
            // const dueDate = `${this.datePipe.transform(bill.dueDate, 'MM/dd/yyyy')}`
            const details = this.formBuilder.group({
              id: [bill.id],
              bill: [bill.id],
              dueDate: [dueDate],
              memo: [bill.memo],
              amountDue: [bill.amountDue],
              amount: [null],
              openBalance: [bill.openAmount],
              checked: [false],
            });
            this.detailsArray.push(details);
          });
        }
      });
  }

  onSelectCustomer(id: string) {
    this.accountingService.getOpenInvoices(id).subscribe((data: any) => {
      this.openInvoices = data.openInvoices;
      if (this.openInvoices.length) {
        this.openInvoices.map(invoice => {
          const dueDate = new Date(invoice.dueDate);
          // const dueDate = `${this.datePipe.transform(invoice.dueDate, 'MM/dd/yyyy')}`
          const details = this.formBuilder.group({
            id: [invoice.id],
            invoice: [invoice.id],
            dueDate: [dueDate],
            description: [invoice.memo],
            amountDue: [invoice.subtotal],
            amount: [null],
            openBalance: [invoice.balanceRemaining],
            checked: [false],
          });
          this.detailsArray.push(details);
        });
      }
    });
  }

  getData() {
    switch (this.currentList.type) {
      case "bills":
        this.loadDataBill();
        break;
      case "charges":
        this.loadDataCharges();
        break;
      case "checks":
        this.loadDataChecks();
        break;
      case "credit-memos":
        this.loadDataCreditMemo();
        break;
      case "invoices":
        this.loadDataInvoice();
        break;
      case "transfers":
        this.loadDataTransfer();
        break;
    }
  }

  private loadDataBill(): void {
    this.accountingService
      .getBill(this.data.id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data: IResponse<IBillResponse>) => {
        let bill = data as unknown as IBillResponse;
        this.patchValueBillForm(bill);
      });
  }

  private loadDataCharges(): void {
    this.accountingService
      .getCharge(this.data.id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data: IResponse<IChargeResponse>) => {
        let charge = data["charge"];
        this.form.patchValue({
          customerId: charge.customerId,
          reference: charge.referenceNumber,
          date: charge.date,
          dueDate: charge.dueDate,
          memo: charge.memo,
          itemId: charge.itemId,
          quantity: charge.quantity,
          rate: charge.rate,
          amount: charge.amount,
        });
        this.onChangeAmount();
      });
  }

  private loadDataChecks(): void {
    this.accountingService
      .getCheck(this.data.id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data: IResponse<ICheckResponse>) => {
        let check = data["check"];
        this.form.patchValue({
          entityId: check.entityId,
          reference: check.referenceNumber,
          date: check.date,
          memo: check.memo,
          accountId: check.accountId,
          amount: check.amount,
          amountWords: check.amountWords,
        });
        if (check.expenseDetails.length) {
          check.expenseDetails.map(detail => {
            let details = this.formBuilder.group({
              id: [detail.id],
              customerId: [detail.customerId],
              memo: [detail.memo],
              accountId: [detail.accountId],
              amount: [detail.amount],
              billable: check.isBillable,
            });
            this.detailsArray.push(details);
          });
        } else {
          this.onAddItem();
        }
        this.onChangeAmount();
      });
  }

  private loadDataCreditMemo(): void {
    this.accountingService
      .getCreditMemo(this.data.id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data: any) => {
        let creditMemo = data["creditMemo"];
        this.form.patchValue({
          customerId: creditMemo.customerId,
          reference: creditMemo.referenceNumber,
          termsId: creditMemo.termsId,
          date: creditMemo.date,
          dueDate: creditMemo.dueDate,
          memo: creditMemo.memo,
        });
        if (creditMemo.creditMemoDetails.length) {
          creditMemo.creditMemoDetails.map((detail: any) => {
            let details = this.formBuilder.group({
              id: [detail.id],
              customerId: [detail.customerId],
              itemId: [detail.itemId],
              description: [detail.description],
              quantity: [detail.quantity],
              rate: [detail.rate],
              amount: [detail.amount],
              serviceDate: [detail.serviceDate],
            });
            this.detailsArray.push(details);
          });
        } else {
          this.onAddItem();
        }
        this.onChangeAmount();
      });
  }

  private loadDataInvoice(): void {
    this.accountingService
      .getInvoice(this.data.id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data: any) => {
        const { invoiceDetails, referenceNumber, quantity, files, ...rest } = data["invoice"];
      
        this.attachments.set(files);
        this.form.patchValue({
          ...rest,
          reference: referenceNumber,
        });

        invoiceDetails.length ?
          invoiceDetails.forEach((detail: any) => {
            detail.quantity = parseFloat(detail.quantity).toFixed(2);
            detail.rate = parseFloat(detail.rate).toFixed(2);
            this.detailsArray.push(this.formBuilder.group(detail));
          })
        : this.onAddItem();

        this.onChangeAmount();
      });
  }

  private loadDataTransfer(): void {
    this.accountingService
      .getTransfer(this.data.id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data: IResponse<ITransferResponse>) => {
        let transfer = data["transfer"];
        this.form.patchValue({
          fromAccountId: transfer.fromAccountId,
          toAccountId: transfer.toAccountId,
          fromBalance: transfer.fromAccountBalance,
          toBalance: transfer.toAccountBalance,
          amount: transfer.transactionExpenseDetail.amount,
          reference: transfer.transaction.referenceNumber,
          date: transfer.date,
          memo: transfer.memo,
        });
        this.onChangeAmount();
      });
  }

  private patchValueBillForm(bill: IBillResponse): void {
    this.form.patchValue({
      ...bill,
      reference: bill.referenceNumber,
      vendorId: bill.transaction.entityId,
    });

    const expenseDetailsArray = this.formBuilder.array(
      bill.expenseDetails.map(detail =>
        this.formBuilder.group({
          id: [detail.id],
          accountId: [detail.accountId],
          amount: [detail.amount],
          memo: [detail.memo],
          serviceDate: [null],
          customerId: [detail.customerId],
          billable: [false],
        })
      )
    );

    this.form.setControl("detailsArray", expenseDetailsArray);
    this.attachments.set(bill.files as any)

    if (!bill.expenseDetails.length) {
      this.onAddItem();
    }
  }

  generateCustomerName(data: any[]): any[] {
    return data.map(item => ({
      ...item,
      name: `${item.accountNumber} - ${item.generation} - ${item.name}`,
    }));
  }

  saveData(action: SaveType = "new"): void {
    const isFormValid = this.form.valid;
    this.saveType = action;

    if (isFormValid) {
      this.onSave();
    } else {
      this.form.markAllAsTouched();
    }
  }

  onSave(): void {
    let request: any;
    this.setLoadingSubmit(true);

    switch (this.currentList.type) {
      case "bills":
        request = this.saveBill();
        break;
      case "charges":
        request = this.saveCharges();
        break;
      case "checks":
        request = this.saveCheck();
        break;
      case "credit-memos":
        request = this.saveCreditMemo();
        break;
      case "invoices":
        request = this.saveInvoice();
        break;
      case "transfers":
        request = this.saveTransfer();
        break;
    }

    this.submitPayment(request);
  }

  private submitPayment(request: Observable<any>): void {
    request.subscribe({
      next: (resp: any) => {
        if (this.saveType === "new" && !this.editMode) {
          this.form.reset();
          this.form.markAsUntouched();
          this.totalAmount = 0;

          this.detailsArray.clear();
          this.detailsArray.setValue([]);
          this.detailsArray.push(this.formItems);
          this.attachments.set([]);
        } else {
          this.dialogRef.close();
        }

        this.setLoadingSubmit();
        this.accountingService.refresh.next(true);
        this.toastr.success("Success");
      },
      error: () => {
        this.setLoadingSubmit();
      },
    });
  }

  private saveBill(): Observable<any> {
    let body: any = {
      propertyId: this.selectedAssociationId(),
      groupId: this.form.controls["vendorId"].value,
      termsId: this.form.controls["termsId"].value,
      date: this.form.controls["date"].value,
      dueDate: this.form.controls["dueDate"].value,
      amountDue: this.totalAmount.toString(),
      referenceNumber: this.form.controls["reference"].value,
      memo: this.form.controls["memo"].value,
      expenseDetails: this.covertExpenseDetails(this.form.value.detailsArray),
      files: this.attachments()
    };

    return this.editMode ?
        this.accountingService.editBill(this.data.id, body)
      : this.accountingService.createBill(body);
  }

  private saveCharges(): Observable<any> {
    const body: any = {
      propertyId: this.selectedAssociationId(),
      customerId: this.form.controls["customerId"].value,
      referenceNumber: this.form.controls["reference"].value,
      date: this.form.controls["date"].value,
      dueDate: this.form.controls["dueDate"].value,
      itemId: this.form.controls["itemId"].value,
      description: this.form.controls["description"].value,
      quantity: this.form.controls["quantity"].value,
      rate: this.form.controls["rate"].value,
      amount: this.form.controls["amount"].value,
      memo: this.form.controls["memo"].value,
      files: this.attachments()
    };

    return this.editMode ?
        this.accountingService.editCharge(this.data.id, body)
      : this.accountingService.createCharge(body);
  }

  private saveCheck(): Observable<any> {
    const body: any = {
      propertyId: this.selectedAssociationId(),
      entityId: this.form.controls["entityId"].value,
      referenceNumber: this.form.controls["reference"].value,
      date: this.form.controls["date"].value,
      amount: this.form.controls["amount"].value,
      amountWords: this.form.controls["amountWords"].value,
      memo: this.form.controls["memo"].value,
      expenseDetails: this.form.value.detailsArray,
      files: this.attachments(),
    };

    return this.editMode ?
        this.accountingService.editCheck(this.data.id, body)
      : this.accountingService.createCheck(body);
  }

  private saveCreditMemo(): Observable<any> {
    const body: any = {
      propertyId: this.selectedAssociationId(),
      customerId: this.form.controls["customerId"].value,
      termsId: this.form.controls["termsId"].value,
      referenceNumber: this.form.controls["reference"].value,
      date: this.form.controls["date"].value,
      dueDate: this.form.controls["dueDate"].value,
      subtotal: this.totalAmount,
      memo: this.form.controls["memo"].value,
      creditMemoDetails: this.form.value.detailsArray,
      files: this.attachments(),
    };

    return this.editMode ?
        this.accountingService.editCreditMemo(this.data.id, body)
      : this.accountingService.createCreditMemo(body);
  }

  private saveInvoice(): Observable<any> {
    let body: any = {
      propertyId: this.selectedAssociationId(),
      customerId: this.form.controls["customerId"].value,
      termsId: this.form.controls["termsId"].value,
      referenceNumber: this.form.controls["reference"].value,
      date: this.form.controls["date"].value,
      dueDate: this.form.controls["dueDate"].value,
      subtotal: this.totalAmount,
      memo: this.form.controls["memo"].value,
      invoiceDetails: this.convertItemDetailInvoice(
        this.form.value.detailsArray
      ),
      files: this.attachments(),
    };

    return this.editMode ?
        this.accountingService.editInvoice(this.data.id, body)
      : this.accountingService.createInvoice(body);
  }

  private saveTransfer(): Observable<any> {
    const body: any = {
      propertyId: this.selectedAssociationId(),
      customerId: this.form.controls["customerId"].value,
      referenceNumber: this.form.controls["reference"].value,
      date: this.form.controls["date"].value,
      memo: this.form.controls["memo"].value,
      fromAccountId: this.form.controls["fromAccountId"].value,
      toAccountId: this.form.controls["toAccountId"].value,
      fromAccountBalance: this.form.controls["fromBalance"].value,
      toAccountBalance: this.form.controls["toBalance"].value,
      amount: this.totalAmount,
      files: this.attachments(),
    };
    return this.editMode ?
        this.accountingService.editTransfer(this.data.id, body)
      : this.accountingService.createTransfer(body);
  }
  covertExpenseDetails(detailsArray: any[]): any[] {
    const expenses = detailsArray.map(item => ({ ...item }));
    return expenses.map(item => ({
      ...item,
      amount: convertCurrencyToNumber(item.amount),
    }));
  }

  convertItemDetailInvoice(invoiceItems: any[]): any[] {
    const expenses = invoiceItems.map(item => ({ ...item }));
    return expenses.map(item => ({
      ...item,
      quantity: +item.quantity,
      rate: convertCurrencyToNumber(item.rate),
      amount: convertCurrencyToNumber(item.amount),
    }));
  }

  onChangeAmount() {
    let initialValue = 0;
    this.totalAmount = this.form.value.detailsArray.reduce(
      (data: number, currentData: any) => {
        return data + convertCurrencyToNumber(currentData.amount);
      },
      initialValue
    );

    this.cdr.detectChanges();
  }

  onChangeTransferAmount() {
    this.totalAmount = this.form.controls["amount"].value;
  }

  onChangeCheckedAmount() {
    this.totalAmount = 0;
    let detailArrays = this.covertExpenseDetails(this.form.value.detailsArray);

    detailArrays.map((data: any) => {
      if (data.checked) {
        this.totalAmount += +data.amount;
      }
    });
  }

  onChangePrice(value: any, index: number) {
    let newForm = this.form.value.detailsArray[index];
    let formArr = <FormArray>this.form.controls["detailsArray"];
    if (newForm.quantity && newForm.rate) {
      formArr.controls[index].patchValue({
        amount: +newForm.quantity * +newForm.rate,
      });
    } else {
      formArr.controls[index].patchValue({
        amount: 0,
      });
    }
    this.onChangeAmount();
  }

  // Charges
  onSelectChargeItem(id: string) {
    let selectedItem: any = this.items.find(item => item.id === id);
    this.form.get("description")?.setValue(selectedItem.description);
    this.form.get("rate")?.setValue(selectedItem.rate);
  }

  onChangeChargePrice() {
    let quantity = this.form.controls["quantity"].value;
    let rate = convertCurrencyToNumber(this.form.controls["rate"].value);
    if (quantity && rate) {
      this.form.get("amount")?.setValue(+quantity * +rate);
    }
  }

  onChangeCheckAmount() {
    this.onChangeAmount();

    const toWords = new ToWords({
      localeCode: "en-US",
    });
    let words = toWords.convert(this.totalAmount, {
      currency: true,
      doNotAddOnly: true,
    });

    this.form.get("amountWords")?.setValue(words);
  }

  public updateFrequencyForm(): void {
    const isOneTime: boolean = this.frequency === "onetime";
    this.validatorForm.validateFrequencyForm(isOneTime);
  }

  private setLoader(status: boolean = true): void {
    this.loader.vendor = status;
    this.loader.term = status;
  }

  private setLoadingSubmit(isLoading = false): void {
    this.saveType === "new" ?
      this.loadingSave.new.set(isLoading)
    : this.loadingSave.exit.set(isLoading);
  }
}
