<div mat-dialog-title>
  <div class="titlePlace">
    <div [inlineSVG]="'/assets/svg/il-user-home.svg'"></div>
    <div class="c-pointer" mat-dialog-close>
      <img [src]="icon.closeBlack.src" [alt]="icon.closeBlack.alt" />
    </div>
  </div>
</div>
<div class="mat-dialog-roam-content">
  <div class="fw-600 font-24px">Select One Association To Continue</div>
  <mat-form-field appearance="outline" class="dropdownMenu">
    <mat-select
      class="roam-menu"
      placeholder="Select Association"
      panelClass="association-select radio-type reverse"
      [(value)]="state.selectedAssociationKey">
      <mat-select-trigger
        #associationTrigger
        class="align-center justify-between">
        {{ selectedAssociation()?.name || "All Associations" }}
      </mat-select-trigger>
      <div class="search-wrapper">
        <input type="search" placeholder="Search" [(ngModel)]="state.keyword" />
        <mat-icon>search</mat-icon>
      </div>
      <mat-option [value]="'all'">
        <p class="roam-text">All Associations</p>
      </mat-option>
      @for (ass of displayedAssociations(); track ass.id) {
        <mat-option [value]="ass.id">
          <p class="roam-text">{{ ass.name }}</p>
        </mat-option>
      }
    </mat-select>
    <button matSuffix mat-icon-button type="button">
      <mat-icon class="arrow-down">expand_more</mat-icon>
    </button>
  </mat-form-field>
</div>
<mat-dialog-actions class="mat-dialog-roam-action gap-10">
  <button
    mat-button
    mat-dialog-close
    class="btn-close c-pointer"
    (click)="close()">
    <span class="text-underline"> Cancel </span>
  </button>
  <app-roam-button
    [class]="'btn btn-primary bold pr-24 pl-24 h-45'"
    [label]="'Next'"
    (onClick)="next()">
  </app-roam-button>
</mat-dialog-actions>
